<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { apiRequest } from "@/helpers/api-call";

export default {
  components: {
    Layout,
    PageHeader,
    //'gmap-custom-marker': GmapCustomMarker,
  },
  data() {
    return {
      title: "VUE GLOBALE",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "VUE GLOBALE",
          active: true,
        },
      ],
      center: { lat: 6.3633, lng: 2.4300 },
      zoom: 6,
      trajets: [],
      openInfoWindows: {},
    };
  },
  mounted() {
    this.fetchTrajetsPasse();
  },
  methods: {
    async fetchTrajetsPasse() {
      try {
        const response = await apiRequest("GET", "admin/trajets/passes", undefined, false);
        console.log("response", response);

        if (response && response.status === 200) {
          this.trajets = response.data.trajet;
          this.updateMapCenter();
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des trajets passés:', error);
      }
    },
    updateMapCenter() {
      if (this.trajets.length > 0) {
        const firstTrajet = this.trajets[0];
        this.center = {
          lat: (firstTrajet.lieuDepart.latitude + firstTrajet.lieuArrivee.latitude) / 2,
          lng: (firstTrajet.lieuDepart.longitude + firstTrajet.lieuArrivee.longitude) / 2,
        };
      }
    },
    getTrajetPath(trajet) {
      return [
        { lat: trajet.lieuDepart.latitude, lng: trajet.lieuDepart.longitude },
        { lat: trajet.lieuArrivee.latitude, lng: trajet.lieuArrivee.longitude },
      ];
    },
    toggleInfoWindow(index, type) {
      this.$set(this.openInfoWindows, index, this.openInfoWindows[index] === type ? null : type);
    },
    closeInfoWindow(index) {
      this.$set(this.openInfoWindows, index, null);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <h4 class="ml-4 mb-3">
        <span class="text-danger"><ion-icon name="map"></ion-icon></span> Cartographie des trajets passés
      </h4>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100%; height:2200px">
              <template v-for="(trajet, index) in trajets">
                <GmapPolyline :key="`path-${index}`" :path="getTrajetPath(trajet)"
                  :options="{ strokeColor: '#FF0000', strokeOpacity: 1.0, strokeWeight: 2 }" />
                <GmapMarker :key="`start-${index}`"
                  :position="{ lat: trajet.lieuDepart.latitude, lng: trajet.lieuDepart.longitude }"
                  :icon="{ url: require('@/assets/images/start-icon.png'), scaledSize: { width: 30, height: 30 } }" />
                <GmapMarker :key="`end-${index}`"
                  :position="{ lat: trajet.lieuArrivee.latitude, lng: trajet.lieuArrivee.longitude }"
                  :icon="{ url: require('@/assets/images/end-icon.png'), scaledSize: { width: 30, height: 30 } }"
                  @click="toggleInfoWindow(index, 'arrivee')" />
                <GmapInfoWindow :key="`info-${index}`"
                  :position="{ lat: trajet.lieuArrivee.latitude, lng: trajet.lieuArrivee.longitude }"
                  :opened="openInfoWindows[index] === 'arrivee'" @closeclick="closeInfoWindow(index)">
                  <div>
                    <h4>Avis du conducteur</h4>
                    <p>{{ trajet.avisConduc && trajet.avisConduc.avis !== null ? trajet.avisConduc.avis : 'Aucun avis'
                      }}</p>
                    <p>Note : {{ trajet.avisConduc && trajet.avisConduc.rating !== null ? trajet.avisConduc.rating :
                      'Aucune note' }}/5</p>

                    <h4>Avis des passagers</h4>
                    <div v-for="(avisPassager, avisIndex) in trajet.avisPassagers" :key="avisIndex">
                      <p>{{ avisPassager && avisPassager.avis !== null ? avisPassager.avis : 'Aucun avis' }}</p>
                      <p>Note : {{ avisPassager && avisPassager.rating !== null ? avisPassager.rating : 'Aucune note'
                        }}/5</p>
                      <hr v-if="avisIndex < trajet.avisPassagers.length - 1">
                    </div>
                  </div>
                </GmapInfoWindow>
              </template>
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
